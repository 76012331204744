import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomerObject } from 'src/app/_interfaces/cinema/CustomerObject';
import { environment } from 'src/environments/environment';
const API_URL = environment.prismaApiUrl +'/api/dispo/contacts';
@Injectable({
 providedIn: 'root'
})
export class ContactService {
currentObject: any;
isNew: Boolean = false;

        constructor(private http: HttpClient) { }

        getAll(): Observable<any> {
            return this.http.get(API_URL);
        }

        store(object: any): Observable<any> {
            return this.http.post<CustomerObject>(API_URL, {data: object});
        }

        getModuleContact(id: any, module_id: any) {
            return this.http.get(API_URL + '/get_module_contacts/' + id + '/' + module_id);
        }

        delete(object: any): Observable<any> {
            return this.http.delete(API_URL + '/' + object.id);
        }

       show(id: any): Observable<any> {
            this.currentObject = this.http.get(API_URL + '/' + id);
            return this.currentObject;
        }

        update(object: any): Observable<any> {
            return this.http.put<CustomerObject>(API_URL + '/' + object.id, {data: object});
        }

        genPDF(): Observable<any> {
            return this.http.get(API_URL + '/gen-pdf', {responseType: 'arraybuffer'});
        }

        getCurrentObject() {
            return this.currentObject;
        }

        setIsNew(value: any) {
            this.isNew = value;
        }

        getIsNew() {
            return this.isNew;
        }
      }
