<div id='backdrop' class='modal-backdrop' [ngClass]='{ show: showModal }'></div>
<div class='modal d-block fade' 
[ngClass]='{ show: showModal }'
(click)='goBack()'
id='listing-dialog' 
tabindex='-1' role='dialog' aria-labelledby='modalIdLabel'>
<div class='modal-dialog mw-100 w-75' role='document' (click)='onDialogClick($event)'>
<div class='modal-content'>
    <div class='modal-header'>
                <h5 *ngIf='showModal'>Import</h5>
                <button type='button'
                    class='close'
                    (click)='goBack()'
                    aria-label='Close'><span aria-hidden='true'>&times;</span>
                </button>
            </div>
            <div class='modal-body'>

              <mat-form-field appearance="outline" class="w-full ml-1" style="width: 200px;">
                <mat-label>Schema</mat-label>
                <select matNativeControl (change)="onSelectTable($event)">
                  <option value="">Datenbank wählen</option>
                  <option value="bio_db_spectra_entries">BioDB</option>
                  <option value="orders">Aufträge</option>
                  <option value="icds">IcdDB</option>
                  <option value="ebm_items">EbmDB</option>
                  <option value="genes">GeneDB</option>
                </select>
              </mat-form-field>

              <label for="fileInput" class="file-button" style="height: 49px;">
                <span>File</span>
                <input type="file" id="fileInput" name="fileInput" (change)="onFileSelected($event)" placeholder="file">
              </label>
              
              <button (click)="onUpload()" type="button" style="height: 49px; margin-top: 5px" class="w-20 btn btn-icon action-icon d-sm-block">
                <i class="ficon feather ft-upload"></i>
              </button>    


            <button type="button" class="w-20 ml-1 btn btn-icon action-icon d-sm-block bg-warning text-white" (click)="killAll()">
                Kill
            </button>


            </div>
           
        </div>
    </div>
</div>
