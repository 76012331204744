import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from 'src/app/_services/dispo/contact.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImportService } from 'src/app/_services/dispo/import.service.';


@Component({
selector: 'app-import',
templateUrl: 'import.component.html',
styleUrls: ['import.component.css']
})
export class ImportComponent implements OnInit {
customerId='';
  customerObject: any;
  content?: string;
  isNew? : Boolean;
  selectedFile: File = null;
  selectedTable: any;
  uploadProgress: any;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private toastr: ToastrService,
    private importService: ImportService,
    private contactService: ContactService) { }
    
  ngOnInit(): void {
    //this.customerId = this.route.snapshot.params.id;
    /*
    this.contactService.show(this.customerId).subscribe(
      data => {
        this.customerObject = data;
        this.isNew = this.contactService.getIsNew();
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
    */
   
  }

  showModal = false;

  ngAfterContentInit() {
    this.showModal = true;
  }

  onUpload() {
    this.importService.uploadFile(this.selectedFile, this.selectedTable).subscribe(event => {
      this.toastr.success('Daten wurden erfolgreich hochgeladen', 'Datenupload');
    }, error => {
      console.error(error); // Handle errors
    });
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onSelectTable(event) {
    this.selectedTable = event.target.value;
  }

  killAll() {
    /*
    this.bioDbSpectraService.killAll().subscribe(
      data => {
        this.toastr.warning('Daten wurden gelöscht', 'Datenkill');
        this.fetchData();
      },
      err => {
        this.customerList = JSON.parse(err.error).message;
      }
    );
    */
  }
  

 

  goBack() {
    this.router.navigate(['../'],{ relativeTo: this.route }).then(() => {});
  }

  onDialogClick(event: UIEvent) {
    event.stopPropagation();
    event.cancelBubble = true;
  }
}
