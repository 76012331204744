import { Routes, RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './_layout/public-layout/public-layout.component';
import { PrivateLayoutComponent } from './_layout/private-layout/private-layout.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login';
import { ChangelogComponent } from './changelog/changelog.component';
import { FullLayoutComponent } from './_layout/full-layout/full-layout.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { TermsConditionComponent } from './login/terms-condition/terms-condition.component';
const appRoutes: Routes = [
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'termCondition', component: TermsConditionComponent },
  // Public layout
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: '', component: LoginComponent },

    ]
  },
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      { path: 'error', loadChildren: () => import('../app/content/full-pages/error/error.module').then(m => m.ErrorModule)
      , canActivate: [AuthGuard] },
      {
        path: 'authentication', loadChildren: () => import('../app/content/full-pages/authentication/authentication.module').then(m =>
          m.AuthenticationModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'others', loadChildren: () => import('../app/content/full-pages/others/others.module').then(m => m.OthersModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  // Private layout
  {
    path: '',
    component: PrivateLayoutComponent,
    children: [
      { path: 'logout', component: LoginComponent, canActivate: [AuthGuard] },
      { path: 'changelog', component: ChangelogComponent, canActivate: [AuthGuard] },
      { path: 'dashboard', loadChildren: () => import('../app/content/dashboard/dashboard.module').then(m => m.DashboardModule)
      , canActivate: [AuthGuard] },



      //order
      { path: 'order', loadChildren: () => import('../app/content/order/order.module').then(m => m.OrderModule)
      , canActivate: [AuthGuard] },
      { path: 'personal', loadChildren: () => import('../app/content/resource/personal/personal.module').then(m => m.PersonalModule)
      , canActivate: [AuthGuard] },
      { path: 'vehicle', loadChildren: () => import('../app/content/resource/vehicle/vehicle.module').then(m => m.VehicleModule)
      , canActivate: [AuthGuard] },
      { path: 'backup', loadChildren: () => import('../app/content/common/backup/backup.module').then(m => m.BackupModule)
      , canActivate: [AuthGuard] },

       //crm
       { path: 'kunden', loadChildren: () => import('../app/content/crm/customer/customer.module').then(m => m.CustomerModule)
       , canActivate: [AuthGuard] },

      //cinema

      { path: 'pos', loadChildren: () => import('../app/content/cinema/pos/pos.module').then(m => m.PosModule)
      , canActivate: [AuthGuard] },
      { path: 'cb', loadChildren: () => import('../app/content/cinema/cb/cb.module').then(m => m.CbModule)
      , canActivate: [AuthGuard] },
      { path: 'day-report', loadChildren: () => import('../app/content/cinema/day-report/day-report.module').then(m => m.DayReportModule)
      , canActivate: [AuthGuard] },
      { path: 'logs', loadChildren: () => import('../app/content/cinema/logs/logs.module').then(m => m.LogsModule)
      , canActivate: [AuthGuard] },
      { path: 'reservation', loadChildren: () => import('../app/content/cinema/reservation/reservation.module').then(m => m.ReservationModule)
      , canActivate: [AuthGuard] },
      { path: 'ticket', loadChildren: () => import('../app/content/cinema/ticket/ticket.module').then(m => m.TicketModule)
      , canActivate: [AuthGuard] },

      //finance
      { path: 'billing', loadChildren: () => import('../app/content/finance/billing.module').then(m => m.BillingModule)
      , canActivate: [AuthGuard] },

      //goods
      { path: 'goods', loadChildren: () => import('../app/content/goods/goods.module').then(m => m.GoodsModule)
      , canActivate: [AuthGuard] },
      
      //theater
      { path: 'movie-manager', loadChildren: () => import('../app/content/theater/movie-manager/movie-manager.module').then(m => m.MovieManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'scheduler', loadChildren: () => import('../app/content/theater/scheduler/scheduler.module').then(m => m.SchedulerModule)
      , canActivate: [AuthGuard] },
      
      //admin
      { path: 'system-setting', loadChildren: () => import('../app/content/admin/system-setting/system-setting.module').then(m => m.SystemSettingModule)
      , canActivate: [AuthGuard] },
      { path: 'account-setting', loadChildren: () => import('../app/content/admin/account-setting/account-setting.module').then(m => m.AccountSettingModule)
      , canActivate: [AuthGuard] },
      { path: 'backup-manager', loadChildren: () => import('../app/content/admin/backup-manager/backup-manager.module').then(m => m.BackupManagerModule)
      , canActivate: [AuthGuard] },
      
      //base
      { path: 'base', loadChildren: () => import('../app/content/base/base.module').then(m => m.BaseModule)
      , canActivate: [AuthGuard] },
      { path: 'user-manager', loadChildren: () => import('../app/content/base/user-manager/user-manager.module').then(m => m.UserManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'ticket-manager', loadChildren: () => import('../app/content/base/ticket-manager/ticket-manager.module').then(m => m.TicketManagerModule)
      , canActivate: [AuthGuard] },
     
      { path: 'rentals-manager', loadChildren: () => import('../app/content/base/rentals-manager/rentals-manager.module').then(m => m.RentalsManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'product-manager', loadChildren: () => import('../app/content/base/product-manager/product-manager.module').then(m => m.ProductManagerModule)
      , canActivate: [AuthGuard] },
      { path: 'bank-account-manager', loadChildren: () => import('../app/content/base/bank-account-manager/bank-account-manager.module').then(m => m.BankAccountManagerModule)
      , canActivate: [AuthGuard] },



      //biodb
      { path: 'spectra', loadChildren: () => import('../app/content/biodb/spectra/spectra.module').then(m => m.SpectraModule)
      , canActivate: [AuthGuard] },

      { path: 'todo-app', loadChildren: () => import('../app/content/applications/todo-app/todo-app.module').then(m => m.TodoAppModule)
      , canActivate: [AuthGuard] },
      { path: 'chats', loadChildren: () => import('../app/content/applications/chat/chats.module').then(m => m.ChatsModule),
        canActivate: [AuthGuard]},
      { path: 'email', loadChildren: () => import('../app/content/applications/email/email.module').then(m => m.EmailModule)
      , canActivate: [AuthGuard] },
      {
        path: 'calender', loadChildren: () => import('../app/content/applications/calender/calender.module').then(m => m.CalenderModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'kanban', loadChildren: () => import('../app/content/applications/kanban-app/kanban-app.module').then(m => m.KanbanAppModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contacts', loadChildren: () => import('../app/content/common/contact/contact.module').then(m => m.ContactModule),
        canActivate: [AuthGuard]
      },
     
      

    ],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: 'changelog' }
];

export const routing = RouterModule.forRoot(appRoutes, { scrollOffset: [0, 0], scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' });
