import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(private http: HttpClient) { }

  uploadFile(file: File, tableName: 'orders'): Observable<any> {
    const formData = new FormData();
    formData.append('csv', file, file.name);
    formData.append('table', tableName);
    
    return this.http.post('http://localhost:8000/api/upload', formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };
        } else if (event.type === HttpEventType.Response) {
          return event.body;
        }
      }),
      finalize(() => console.log('Upload completed.'))
    );
  }
}